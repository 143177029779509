export const COURSES = [
  {
    title: 'CSS for JavaScript Developers',
    slug: 'css-for-js',
    href: 'https://css-for-js.dev/',
    isNew: false,
  },
  {
    title: 'The Joy of React',
    slug: 'joy-of-react',
    href: 'https://joyofreact.com',
    isNew: false,
  },
  {
    title: 'Whimsical Animations',
    slug: 'whimsical-animations',
    href: 'https://whimsy.joshwcomeau.com',
    isNew: true,
  },
] as const;
