import * as React from 'react';
import { useSpring, animated } from 'react-spring';
import { styled } from '@linaria/react';

import Link from '@/components/Link';
import IconArrowBullet from '@/components/Icons/IconArrowBullet';

import { COURSES } from './CoursesList.data';
import { New } from './NavigationContents.shared';

const SPRING_CONFIG = {
  tension: 300,
  friction: 16,
};

function CoursesList({ isOpen }: { isOpen: boolean }) {
  const [hoveringCourseSlug, setHoveringCourseSlug] = React.useState<
    string | null
  >(null);

  return (
    <>
      <CSSMascot
        src="/images/css-for-js-mascot-light.png"
        alt="Joy of React mascot"
        style={{
          '--opacity': isOpen ? 1 : 0,
          '--transform': isOpen
            ? 'translateY(30%)'
            : 'translateY(45%)',
          '--transition': isOpen
            ? 'all 400ms 200ms'
            : 'opacity 200ms 0ms, transform 400ms 200ms',
        }}
      />
      <SubList>
        {COURSES.map(({ title, slug, href, isNew }) => (
          <li key={slug}>
            <SubNavLink
              href={href}
              onMouseEnter={() => setHoveringCourseSlug(slug)}
              onMouseLeave={() => setHoveringCourseSlug(null)}
            >
              <IconArrowBullet
                size={16}
                isHovering={slug === hoveringCourseSlug}
              />
              <span>
                {title}
                {isNew && <New>New</New>}
              </span>
            </SubNavLink>
          </li>
        ))}

        <StudentLoginListItem>
          <FancyButton
            width={146}
            height={40}
            href="https://courses.joshwcomeau.com"
          >
            Student Login
          </FancyButton>
        </StudentLoginListItem>
      </SubList>
    </>
  );
}

function FancyButton({
  width,
  height,
  href,
  children,
}: {
  width: number;
  height: number;
  href: string;
  children: React.ReactNode;
}) {
  const [isHovering, setIsHovering] = React.useState(false);

  const scaleX = (width + 6) / width;
  const scaleY = (height + 6) / height;

  const spring = useSpring({
    transform: isHovering
      ? `scale(${scaleX}, ${scaleY})`
      : 'scale(1, 1)',
    config: SPRING_CONFIG,
  });

  return (
    <Button
      href={href}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        width: width / 16 + 'rem',
        height: height / 16 + 'rem',
        '--login-button-border-color': isHovering
          ? 'hsl(200deg 40% 80%)'
          : 'hsl(210deg 30% 20% / 0.15)',
      }}
    >
      <ButtonOutline style={spring} />
      {children}
    </Button>
  );
}

const CSSMascot = styled.img`
  position: absolute;
  bottom: 12px;
  right: -40px;
  transform: translateY(30%);
  width: 45%;
  pointer-events: none;
  animation: slideIn 400ms backwards
    cubic-bezier(0.17, 0.67, 0.33, 1.05);
  animation-delay: 80ms;
  opacity: var(--opacity);
  transform: var(--transform);
  transition: var(--transition);

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(30%) translateX(100%);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    opacity: 1;
    transform: translateY(30%);
    transition: none;
  }
`;

const SubList = styled.ul`
  --hover-bg: var(--color-cloud-300);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  font-size: 0.875rem;
  list-style-type: none;

  html[data-color-mode='dark'] & {
    --hover-bg: var(--color-cloud-400);
  }
`;

const SubNavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;

  &:hover {
    background: var(--hover-bg);
  }
  /*
    This is a subtle thing: if the next item in the grid is hovered, the hover backdrop shows up *above* the focus outline, since it comes later in the DOM. Prevent this by promoting the focused link to use positioned layout, so that it sits atop the hover backdrop.
  */
  &:focus {
    position: relative;
  }
`;
const StudentLoginListItem = styled.li`
  margin-top: auto;
  padding-bottom: 0.5rem;
  padding-left: 8px;
`;

const Button = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: inherit;
  border-radius: 4px;

  &:hover {
    background: var(--hover-bg);
  }
`;
const ButtonOutline = styled(animated.span)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--login-button-border-color);
  border-radius: 6px;
  transition: border-color 200ms;
`;

export default CoursesList;
