import { styled } from '@linaria/react';

export const New = styled.span`
  display: inline-block;
  font-size: 0.625em;
  font-weight: var(--font-weight-bold);
  color: var(--color-secondary);
  transform: translateY(-25%);
  text-transform: uppercase;
  margin-left: 0.5em;
`;
